<template>
  <div class="main-bottom z-10 absolute bottom-0 right-5">
    <div class="flex justify-end items-center">
      <div class="time text-sm shadow">{{ currentTime }}</div>
      <div
        v-if="branding && branding.is_powered"
        class="logo-container flex flex-col"
      >
        <!-- branding.site -->

        <img
          :src="logoUrl"
          @click="redirectToLink"
          class="logo-map cursor-pointer"
          alt="Skif Logo"
        />
        <!-- <svg
            class="logo-map"
            width="177"
            height="19"
            viewBox="0 0 177 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 3.63612V0.453125H21.9165L20.1981 2.81332C19.8217 3.3303 19.2208 3.63612 18.5813 3.63612H12.6194V18.9894H8.13836V3.63612H0Z"
              fill="#292929"
            />
            <path
              d="M30.1543 3.63612V0.453125H48.2234L46.505 2.81332C46.1286 3.3303 45.5277 3.63612 44.8882 3.63612H30.1543Z"
              fill="#292929"
            />
            <path
              d="M30.1543 18.9894V7.94252H47.0647V11.1255H34.8934V18.9894H30.1543Z"
              fill="#292929"
            />
            <path
              d="M65.8943 15.8103V0L62.5577 1.6412C61.8737 1.97763 61.4404 2.6736 61.4404 3.43585V19L79.7857 18.9933V15.8103H65.8943Z"
              fill="#292929"
            />
            <path
              d="M110.464 0.453125H91.4048V3.63612H107.497C107.997 3.63612 108.479 3.44883 108.848 3.11118L110.802 1.32189C111.138 1.01385 110.92 0.453125 110.464 0.453125Z"
              fill="#292929"
            />
            <path
              d="M110.745 7.66167H91.4048V10.8447H107.778C108.278 10.8447 108.76 10.6574 109.128 10.3197L111.083 8.53043C111.419 8.22239 111.201 7.66167 110.745 7.66167Z"
              fill="#292929"
            />
            <path
              d="M110.753 15.8006L91.4048 15.7128V18.9894H107.778C108.278 18.9894 108.76 18.8021 109.128 18.4644L111.089 16.6693C111.424 16.3621 111.208 15.8026 110.753 15.8006Z"
              fill="#292929"
            />
            <path
              d="M142.909 0.453125H123.85V3.63612H139.942C140.442 3.63612 140.924 3.44883 141.293 3.11118L143.247 1.32189C143.583 1.01385 143.365 0.453125 142.909 0.453125Z"
              fill="#292929"
            />
            <path
              d="M143.19 7.66167H123.85V10.8447H140.223C140.723 10.8447 141.204 10.6574 141.573 10.3197L143.527 8.53043C143.864 8.22239 143.646 7.66167 143.19 7.66167Z"
              fill="#292929"
            />
            <path
              d="M143.198 15.8006L123.85 15.7128V18.9894H140.223C140.723 18.9894 141.204 18.8021 141.573 18.4644L143.534 16.6693C143.869 16.3621 143.653 15.8026 143.198 15.8006Z"
              fill="#292929"
            />
            <path
              d="M154.113 3.63612V0.453125H176.029L174.311 2.81332C173.934 3.3303 173.334 3.63612 172.694 3.63612H166.732V18.9894H162.251V3.63612H154.113Z"
              fill="#292929"
            />
          </svg> -->

        <a
          href="https://skif.pro"
          target="_blank"
          rel="noopener noreferrer"
          class="powered-by"
          >Powered by SKIF.PRO</a
        >
      </div>
      <div v-else class="skif-logo ml-4">
        <img
          src="@/assets/img/skif-logo.svg"
          class="logo-map"
          alt="Skif Logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'footer-tools',
  data: () => {
    return {
      visible: false,
      filterModal: false,
      tz: '',
      currentTime: null,
      filterString: '',
      data: [],
      formattedCurrentTime: null,
      logoUrl: ''
    }
  },

  computed: {
    ...mapState('login', {
      me: (state) => state.me
    }),
    ...mapGetters({
      branding: 'login/getBranding'
    }),
    ...mapGetters('login', {
      timeZoneDiff: 'timeZoneDiff',
      branding: 'getBranding'
    })
  },

  methods: {
    ...mapMutations({
      setFormattedCurrentTime: 'login/setFormattedCurrentTime'
    }),
    redirectToLink() {
      if (this.branding && this.branding.site) {
        window.open(this.branding.site, '_blank')
      }
    },

    updateCurrentTime() {
      this.currentTime = moment()
        .utc()
        .add(this.timeZoneDiff, 'hours')
        .format('HH:mm')
      this.formattedCurrentTime = moment()
        .utc()
        .add(this.timeZoneDiff, 'hours')
        .format('YYYY-MM-DD HH:mm:ss')
    }
  },

  mounted() {
    if (this.branding && this.branding.is_powered) {
      this.logoUrl = ` /api_v1/${this.branding.logo}`
    } else {
      this.logoUrl = require('@/assets/img/skif-logo.svg')
    }
    this.currentTime = moment()
      .utc()
      .add(this.timeZoneDiff, 'hours')
      .format('HH:mm')

    setInterval(() => {
      this.updateCurrentTime()
      this.setFormattedCurrentTime(this.formattedCurrentTime)
      setTimeout(() => {
        this.$store.dispatch(
          'company/GET_CURRENTTIME',
          this.formattedCurrentTime
        )
      }, 1000)
    }, 1 * 1000)
  }
}
</script>

<style lang="stylus" scoped>
$blue = #5477A9;

.main-bottom {
  z-index: 9;

  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 138px;
    height: 36px;
    color: $blue;
    background: white;
    border-radius: 10px;
  }
}
.logo-container{
  background: #FFF;
  margin-left: 20px;
  padding-bottom: 0
  border-radius: 12px 12px 0px 0px;
  border: 1px solid #DCE1E7;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex
  align-items: center;
  justify-content: center;

.logo-map {
  max-height:80px;
  max-width: 400px;
  padding-left:16px;
  padding-right: 16px;
  margin-top: 16px;

}
.powered-by {
  margin-top: 11px;
  color: #a6aeb8;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background: #EDEFF3;
  width: 100%;
  display: flex;
  height: 22px;
  padding: 4px 45px;
  justify-content: center;
  align-items: center;
  cursor:pointer;

  text-transform: uppercase;
}}
.powered-by:hover{
  color: #446ca7;
  transition: 0.3s all;
}

.wrap {
  position: relative;
  margin-right: 10px;

  .book-btn {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 6px 13px;
    line-height: 16px;
    border-radius: 10px;
  }
}
</style>
