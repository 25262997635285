import { render, staticRenderFns } from "./footerMenu.vue?vue&type=template&id=4e2e9670&scoped=true"
import script from "./footerMenu.vue?vue&type=script&lang=js"
export * from "./footerMenu.vue?vue&type=script&lang=js"
import style0 from "./footerMenu.vue?vue&type=style&index=0&id=4e2e9670&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e2e9670",
  null
  
)

export default component.exports